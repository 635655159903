// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  BASE_PATH: "https://eu.europeanvisas.in/",
  //BASE_PATH: 'http://159.203.110.163:3002/',
  //BASE_PATH: 'http://localhost:3001/',
  GOOGLE_PATH: "https://maps.googleapis.com/maps/api/",
  API_KEY: "AIzaSyBs2fh7rBjVtuZC1KUrm3DpW8AJWB-sWkQ",
  firebase: {
    apiKey: "AIzaSyC4TbJkx1sInNdml_asBx4xeyzlpmNwusw",
    authDomain: "hoo-ha-315910.firebaseapp.com",
    projectId: "hoo-ha-315910",
    storageBucket: "hoo-ha-315910.appspot.com",
    messagingSenderId: "526501974450",
    appId: "1:526501974450:web:da3588706c98088c27d4ab",
    measurementId: "G-NSHZ07M5C1",
  },
};
